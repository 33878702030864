/* General body and HTML optimizations */
body,
html {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling for Safari */
}

/* Optimize scroll containers */
.scroll-container {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

/* Animated elements for GPU acceleration */
.motion-element {
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;
}

/* Button hover and active state */
motion-button {
  transition: transform 0.2s ease;
}

motion-button:hover {
  transform: scale(1.05);
}

motion-button:active {
  transform: scale(0.95);
}

/* Minimize box shadow impact */
.shadow-md {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Reduced intensity for mobile */
}

/* Fixed headers optimization */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it stays on top */
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Optimize image loading and display */
img {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

img[loading="lazy"] {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

img[loading="lazy"].loaded {
  opacity: 1;
}

/* Image carousel optimizations */
.image-carousel {
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on mobile */
}

.image-carousel img {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Fix z-index and layout shifts */
.section-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

/* Section headings */
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #0a2342;
}

/* Buttons and highlights */
.button {
  border: 1px solid #0a2342;
  background-color: white;
  color: #0a2342;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
}

.button:hover {
  background-color: #0a2342;
  color: white;
}

.button-active {
  background-color: #0a2342;
  color: white;
}

/* Lazy-loaded sections */
.lazy-section {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.lazy-section.visible {
  opacity: 1;
}

/* Reduce z-index conflicts */
.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-100 {
  z-index: 100;
}

/* Optimize table rendering */
.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ddd;
}

/* Optimize grid layouts */
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-gap {
  gap: 1rem;
}

/* Optimize padding and margins */
.section {
  padding: 1rem;
  margin-bottom: 1rem;
}

.section:last-child {
  margin-bottom: 0;
}

.navigation-button {
  padding: 8px 16px;
  white-space: nowrap;
  /* border-radius: 4px; */
  transition: all 0.3s ease-in-out;
}

.navigation-button.active {
  background-color: #0a2342;
  color: white;
}

.navigation-button.inactive {
  background-color: white;
  color: #0a2342;
  border: 1px solid #0a2342;
}

.navigation-button:hover {
  background-color: #082132;
  color: white;
}

.submit-button {
  width: 100%;
  padding: 12px 0;
  margin-top: 16px;
  background-color: #0a2342;
  color: white;
  /* border-radius: 4px; */
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #082132;
}

.submit-button:active {
  transform: scale(0.98);
}
