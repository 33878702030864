/* Custom styles for react-toastify toasts */
.Toastify__toast {
  background-color: white; /* Your theme color */
  border-color: #0a2342;
  color: #0a2342; /* Text color */
  border-radius: 0; /* Sharp edges */
  box-shadow: none; /* Remove shadow for a sharper look */
}

.Toastify__toast--success {
  border-left: 4px solid #0a2342; /* Optional: Add a left border for success */
}

.Toastify__toast--error {
  border-left: 4px solid #ff4d4d; /* Optional: Add a left border for error */
}

.Toastify__toast-body {
  font-weight: bold; /* Make text bold */
}

.Toastify__close-button {
  color: white; /* Close button color */
}
