.no-scrollbar {
  overflow-x: auto; /* Allow horizontal scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in IE */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers */
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
